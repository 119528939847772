.consent-list {
  li {
    margin-bottom: 1rem;
    font-size: 1rem;
  }
}
.box-container {
  padding: 1px;
  margin-bottom: 2rem;
}
.emli-address {
  font-size: 1rem;
  margin-left: 2rem;
  margin-bottom: 1rem;
}
