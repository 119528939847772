/*
Base Style
*/

@import 'variables.scss';
@import 'App.scss';
@import 'EligibilityPage.scss';
@import 'Header.scss';
@import 'FileUpload.scss';
@import 'Consent.scss';
@import 'ApplicationDetails.scss';
@import 'Login.scss';
@import 'Identification.scss';
@import 'Form.scss';
@import 'RebateTable.scss';
@import 'Footer.scss';

/* BC Sans Regular */
@font-face {
  font-family: 'BCSans';
  src: url('./fonts/BCSans-Regular.woff') format('woff');
}

/* BC Sans Bold */
@font-face {
  font-family: 'BCSans';
  font-weight: bold;
  src: url('./fonts/BCSans-Bold.woff') format('woff');
}

/* BC Sans Bold + Italic */
@font-face {
  font-family: 'BCSans';
  font-style: italic;
  font-weight: bold;
  src: url('./fonts/BCSans-BoldItalic.woff') format('woff');
}

/* BC Sans Italic */
@font-face {
  font-family: 'BCSans';
  font-style: italic;
  src: url('./fonts/BCSans-Italic.woff') format('woff');
}

body,
html {
  background-color: $white;
  height: 100%;
}

body {
  color: $black;
  font-family: 'BCSans', sans-serif;
  margin: 0;
  padding: 0;
}

p {
  color: $black;
  margin-top: 5px;
}

p,
table,
li,
div,
.label,
.MuiTableCell-root {
  font-size: 1.35rem;
}
h1 {
  font-size: 2.5rem;
  font-weight: bold;
}

h2 {
  font-size: 2.1rem;
  font-weight: bold;
}
h3 {
  font-size: 1.7rem;
  font-weight: bold;
}
h4 {
  font-size: 1.5rem;
  font-weight: bold;
}

h5 {
  font-size: 1.4rem;
  font-weight: bold;
}
h6 {
  color: $black;
  font-weight: bold;
  margin-top: 0;
  padding: 0;
  font-size: 1.1rem;
}
.link {
  background-color: transparent;
  border: none;
  color: $default-link-blue;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: underline;
}
a {
  color: $default-link-blue;
}
.button {
  background-color: $button-blue;
  height: 3.5rem;
  color: $white;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 0 solid $button-blue;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: normal;

  &:focus {
    outline: 4px solid #3b99fc;
    outline-offset: 5px;
  }

  &:disabled {
    color: $grey-dark;
    background-image: none;
    opacity: 0.6;
    cursor: auto;
  }
}

.page-content {
  margin: 0 6rem 0 6rem;
  padding-bottom: 6rem;
}

span.validated {
  display: block;
  background-color: rgb(126, 189, 66);
  color: white;
  font-size: 32px;
  text-align: center;
  padding-top: 25px;
}

p.error {
  color: rgb(200, 35, 51);
  margin-bottom: 0px;
}

span.error {
  display: block;
  font-size: 32px;
  font-weight: bold;
  color: rgb(200, 35, 51);
  padding-bottom: 25px;
}

@media (max-width: 992px) {
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.3rem;
  }
  h3 {
    font-size: 1.2rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  h4 {
    font-size: 1.1rem;
  }
  h5 {
    font-size: 1rem;
  }
  p,
  ul,
  li,
  div,
  .error {
    font-size: 1rem !important;
  }

  .error {
    padding-bottom: 0 !important;
  }

  .page-content {
    margin: 0 1rem 0 1rem;
  }
}
