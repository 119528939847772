// Variables for colors //
$text-grey-paragraph: rgb(96, 96, 96);

$border-orange: #fcbc19;

$white: #ffffff;
$black: #000000;

$background-grey-light: #edebeb;
$grey-dark: #8c847c;

$button-blue: #003155;
$banner-blue: #003366;

$default-link-blue: #1a5a96;
$default-background-grey: #f2f2f2;

$background-light-blue: #e7f4f7;

$table-border: rgba(49, 49, 50, 0.33);
