#bceid-login-button {
  margin: 1.5rem 0 0 0;
}
.login-square {
  display: inline-block;
  background-color: $background-light-blue;
  padding: 1.5rem;
  width: 370px;
  height: 220px;
  margin: 0 3rem 2rem 3rem;
}

.whats-needed {
  &-individual,
  &-spouse {
    margin-top: 2rem;
  }
  &-individual {
    background: $background-grey-light;
  }
  &-spouse {
    background: $white;
  }
}

.start-application {
  &-individual,
  &-spouse {
    margin-top: -2rem;
    background-image: url('./images/body-graphic.svg');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    padding-left: 6rem;
    padding-top: 6rem;
    min-height: 55vh;
    background-color: $white;
  }
}

#start-text {
  margin-top: 2.25rem;
  margin-bottom: 1.5rem;
  padding-top: 2rem;
  color: $white;
}
@media (max-width: 820px) {
  .whats-needed {
    &-individual,
    &-spouse {
      padding: 0;
      margin-top: 2rem;
    }
  }
  .start-application {
    &-individual,
    &-spouse {
      background-image: none;
      margin: 0;
      padding: 0.5rem 0 0.5rem;
      min-height: 30vh;
      #start-text {
        margin-top: 0;
        color: $black;
        font-size: 1.5rem;
      }
      a {
        font-size: 1rem;
      }
    }
  }
  #bceid-login-button {
    font-size: 1rem;
  }
  .login-square {
    height: 10rem;
    width: 17rem;
    margin-right: auto;
    padding: 1rem;
    h2 {
      margin: 2px !important;
      font-size: 1.5rem;
    }

    .button {
      font-size: 1rem;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
  }
}
@media (max-width: 514px) {
  .login-square {
    margin-right: auto;
    margin-left: auto;
  }
}
