.identification {
  img {
    @media (max-width: 768px) {
      max-width: 400px;
    }
    @media (max-width: 425px) {
      max-width: 300px;
    }

    @media (max-width: 375px) {
      max-width: 250px;
    }
  }
}
