#eligibility {
  h2 {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  button {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
  #help-email {
    margin-top: 5px;
  }
}
@media (max-width: 820px) {
  #eligibility {
    h2 {
      font-size: 1.5rem;
    }
    h3,
    p,
    li {
      font-size: 1rem;
    }
  }
}
